<template>
  <div id="jcDialerDropDown" class="dropdown" :class="showDialerMenu">
    <mdicon
      id="justcall-cti-dropdown"
      name="phone"
      size="24"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    />
    <div
      id="justcall-cti-container"
      class="dropdown-menu"
      :class="showDialerMenu"
      aria-labelledby="justcall-cti-dropdown"
    >
      <div v-show="!dialerLoaded">
        <div class="d-flex justify-content-center align-items-center">
          <output class="spinner-border"></output>
        </div>
      </div>

      <div
        v-show="dialerLoaded"
        id="justcall-dialer"
        ref="justcallDialerIFrame"
      ></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { JustCallDialer } from "@justcall/justcall-dialer-sdk";
import { onMounted, ref } from "vue";

const dialerLoaded = ref(false);
const showDialerMenu = ref("");

const initDialer = async () => {
  const dialer = new JustCallDialer({
    dialerId: "justcall-dialer",
    onLogin: (data) => console.log("Client receiving Logged in data: ", data),
    onLogout: () => console.log("Client receiving Logged out"),
    onReady: () => console.log("Client receiving on ready callback"),
  });

  dialer.on("call-ringing", (data) => {
    console.log("Client receiving call-ringing data: ", data);
    showDialerMenu.value = "show";
  });

  dialer.on("call-answered", (data) => {
    console.log("Client receiving call-answered data: ", data);
  });

  dialer.on("call-ended", function (data) {
    console.log("Client receiving call-ended data: ", data);
  });

  await dialer.ready();

  if (dialer.isDialerReady) {
    dialerLoaded.value = true;
  }
};

onMounted(async () => {
  await initDialer();
  dialerLoaded.value = true;
  const iframe = document.querySelector(
    "#justcall-dialer>iframe"
  ) as HTMLIFrameElement;
  iframe.style.cssText = iframe.style.cssText + "height: 610px !important;";
});
</script>

<style scoped>
#justcall-cti-dropdown {
  cursor: pointer;
}

#justcall-cti-container {
  left: -200px;
  height: 620px;
  width: 377px;
  margin-top: 6px;
  border-radius: 10px;
  border-block-width: 1px;
}
</style>
